import * as React from 'react'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import './toc.scss'

export default function TableOfContents({data}) {

  const nodePaths = data.allSitePage.nodes.map(node => node.path).sort().filter(path => path.indexOf('404') === -1);
  const divs = nodePaths.map((nodePath, index) => {
    const paths = nodePath.split("/");
    const indention = paths.length - 1;
    var name = paths.pop() || paths.pop();
    name = name.replace(/'-'/g, ' ') || "Home";

    // build string with the given number of spaces
    var indentSize = Array(indention).fill('i').join('');
    if (!nodePath.endsWith('/')) {
      // this stupid indentSize string generates a class that indents appropriately
      indentSize += "i";
    }

    return (<div key={index} className={`link ${indentSize}`}>
      <Link to={nodePath}>{name}</Link>
    </div>);
   })

  return (
    <div className="Wink">
      <h1>WINK - Table of Contents</h1>
      {divs}
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allSitePage {
      nodes {
        path
      }
    }
  }
 `